<template>
  <div class="unload-content-wrapper">
    <unload-content-info :cargo="cargo" />
    <unload-content-cargo
      :vehicles-list="vehiclesList"
      :cargo="cargo"
    />
  </div>
</template>

<script>
export default {
  components: {
    UnloadContentCargo: () => import('./unload-content-cargo.vue'),
    UnloadContentInfo: () => import('./unload-content-info.vue')
  },
  props: {
    vehiclesList: {
      type: Array,
      required: true
    },
    cargo: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.unload-content-wrapper {
  display: grid;
  grid-gap: 1rem;
  padding: 0.5rem;
  overflow: auto;
  height: 100%;
}
</style>
